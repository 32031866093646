var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import LazyLoad from "vanilla-lazyload";
import { lazyConfig } from "../lazy";
var IMG_SELECTOR = 'picture.lazy:not(.lazy--loaded) img:not(.loading)';
export default function LazyImages(_a) {
    var swiper = _a.swiper, on = _a.on, off = _a.off;
    on('init', function (swiper) {
        // задержка нужна, чтобы не происходило отмены загрузки картинок
        setTimeout(function () { return check(swiper); }, 100);
    });
    on('slideChangeTransitionStart', check);
    on('slideChangeTransitionEnd', check);
    function check(swiper) {
        var e_1, _a;
        var slidesPerView = swiper.params.slidesPerView === 'auto' ? Math.round(swiper.width / swiper['slidesSizesGrid'][0]) : swiper.params.slidesPerView;
        var activeIndex = swiper.activeIndex;
        var totalSlides = getTotalSlidesNumber();
        var updated = preloadImages(activeIndex + Math.floor(slidesPerView), activeIndex + Math.ceil(slidesPerView * 2));
        if (!swiper.loopedSlides) {
            return;
        }
        var startIndex = (swiper.realIndex < slidesPerView ? totalSlides + swiper.realIndex - slidesPerView : swiper.realIndex - slidesPerView);
        for (var i = startIndex; i < startIndex + slidesPerView; i++) {
            try {
                for (var _b = (e_1 = void 0, __values(swiper.wrapperEl.querySelectorAll(".".concat(swiper.params.slideClass, "[data-swiper-slide-index=\"").concat(i, "\"] ").concat(IMG_SELECTOR)))), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var image = _c.value;
                    if (preloadImage(image)) {
                        updated = true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        if (updated) {
            return;
        }
        if (swiper.wrapperEl.querySelectorAll(IMG_SELECTOR).length) {
            return;
        }
        off('init', check);
        off('slideChangeTransitionStart', check);
        off('slideChangeTransitionEnd', check);
    }
    function preloadImages(start, end) {
        var updated = false;
        for (var i = start; i < end; i++) {
            if (!swiper.slides[i]) {
                break;
            }
            if (preloadImage(swiper.slides[i].querySelector(IMG_SELECTOR))) {
                updated = true;
            }
        }
        return updated;
    }
    function preloadImage(image) {
        if (!image) {
            return false;
        }
        LazyLoad.load(image, lazyConfig);
        return true;
    }
    function getTotalSlidesNumber() {
        if (!swiper.loopedSlides) {
            return swiper.slides.length;
        }
        return swiper.wrapperEl.querySelectorAll(swiper.params.slideClass).length;
    }
}
