import LazyLoad from "vanilla-lazyload";
export var lazyConfig = {
    elements_selector: 'picture.lazy img, img.lazy',
    callback_loaded: function (element) {
        if (element.parentElement.nodeName === 'PICTURE') {
            element.parentElement.classList.add('lazy--loaded');
        }
        else {
            element.classList.add('lazy--loaded');
        }
    },
};
export default function initLazyLoad() {
    new LazyLoad(lazyConfig);
}
;
