var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { Autoplay, FreeMode, Manipulation, Navigation, Pagination, Swiper, Thumbs } from "swiper";
import AutoplayPauseOnFocus from "./swiper/AutoplayPauseOnFocus";
import FixTabIndex from "./swiper/FixTabIndex";
import LazyImages from "./swiper/LazyImages";
var fourthBreakpoints = {
    500: {
        slidesPerView: 2,
        slidesPerGroup: 2,
    },
    700: {
        slidesPerView: 3,
        slidesPerGroup: 3,
    },
    1000: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 40,
    },
};
var swiperDefaults = {
    speed: 500,
    spaceBetween: 24,
};
export default function initSliders() {
    makeBannersSlider();
    makeBrandsSlider();
    makeReviewsSwiper();
    makeProdCarouselSwiper();
    makeDiscountsSliders();
}
function makeBrandsSlider() {
    makeSwiper('.brands-slider', [Autoplay, LazyImages, FreeMode], {
        loop: true,
        speed: 8000,
        freeMode: {
            enabled: true,
            momentumVelocityRatio: 0.75,
        },
        spaceBetween: 8,
        slidesPerView: 'auto',
        autoplay: {
            delay: 1,
            pauseOnMouseEnter: true,
        },
        on: {
            autoplayStop: function (swiper) { return swiper.autoplay.start(); },
        }
    }, false);
}
function makeDiscountsSliders() {
    makeSwiper('disposable', [Navigation, LazyImages, FreeMode], { freeMode: true, slidesPerView: "auto" });
    makeSwiper('cumulative', [Navigation, LazyImages, FreeMode], { freeMode: true, slidesPerView: "auto" });
    makeSwiper('sale', [Navigation, LazyImages, FreeMode], { freeMode: true, slidesPerView: "auto" });
}
function makeReviewsSwiper() {
    makeSwiper('reviews', [Navigation, LazyImages], {
        slidesPerView: 1.2,
        breakpoints: fourthBreakpoints,
        on: {
            resize: reviewsAutoShowMoreButton,
            afterInit: reviewsAutoShowMoreButton,
        }
    });
}
function reviewsAutoShowMoreButton(swiper) {
    swiper.slides.forEach(function (slide) {
        var moreButton = slide.querySelector('.review-teas__more');
        var textElement = slide.querySelector('.review-teas__text');
        if (textElement.scrollHeight > textElement.clientHeight) {
            moreButton.classList.remove('hidden');
        }
        else {
            moreButton.classList.add('hidden');
        }
    });
}
export function makeProductThumbsSlider() {
    return makeSwiper('#prod-nav-swiper', [Navigation, LazyImages, FreeMode, Manipulation], {
        freeMode: true,
        slidesPerView: 'auto',
        spaceBetween: 40,
        watchSlidesProgress: true,
    });
}
export function makeProductSlider(thumbsSwiper) {
    return makeSwiper('#prod-item-swiper', [Thumbs, LazyImages, Manipulation], {
        thumbs: {
            swiper: thumbsSwiper,
            autoScrollOffset: 0,
        },
    });
}
export function makeBannersSlider() {
    return makeSwiper('.hero-slider', [Autoplay, Pagination, LazyImages], {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 4,
        speed: 1000,
        pagination: {
            el: '.hero-pagination',
        },
    });
}
export function makeTagsSlider() {
    return makeSwiper('#prod-articles-swiper', [Pagination, LazyImages], {
        spaceBetween: 24,
        pagination: {
            el: ".prod-articles__pagination",
        },
    });
}
export function makeSimilarProductsCarousel() {
    return makeProdCarouselSwiper('prod-similar');
}
export function makeProdCarouselSwiper(container) {
    if (container === void 0) { container = 'prod'; }
    return makeSwiper(container, [Navigation, LazyImages], {
        slidesPerView: 1.2,
        breakpoints: fourthBreakpoints,
    });
}
function makeSwiper(container, modules, options, autoPause) {
    var e_1, _a;
    if (modules === void 0) { modules = []; }
    if (options === void 0) { options = {}; }
    if (autoPause === void 0) { autoPause = true; }
    var builder = getOptions(options);
    var name = 'swiper';
    if (typeof container === 'string' && container.match(/^[a-z_-]+$/)) {
        name = container;
        container = "#".concat(name, "-swiper");
    }
    try {
        for (var modules_1 = __values(modules), modules_1_1 = modules_1.next(); !modules_1_1.done; modules_1_1 = modules_1.next()) {
            var module = modules_1_1.value;
            if (module === Autoplay) {
                builder.addAutoplay(autoPause);
            }
            else if (module === Pagination) {
                builder.addPagination();
            }
            else if (module === Navigation) {
                builder.addNavigation(name);
            }
            else {
                builder.addModule(module);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (modules_1_1 && !modules_1_1.done && (_a = modules_1.return)) _a.call(modules_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return new Swiper(container, builder.getOptions());
}
function getOptions(options) {
    var _this = this;
    if (options === void 0) { options = {}; }
    options = __assign(__assign({}, swiperDefaults), options);
    if (!options.modules) {
        options.modules = [];
    }
    var addModule = function (module) {
        options.modules.push(module);
        return _this;
    };
    var addAutoplay = function (pauseOnFocus) {
        if (pauseOnFocus === void 0) { pauseOnFocus = true; }
        addModule(Autoplay);
        if (pauseOnFocus) {
            addModule(AutoplayPauseOnFocus);
        }
        options = __assign(__assign({}, options), { autoplay: __assign({ delay: 5000, pauseOnMouseEnter: true }, makeSpreadable(options.autoplay)) });
        return _this;
    };
    var addNavigation = function (name) {
        if (name === void 0) { name = 'swiper'; }
        addModule(Navigation);
        options.navigation = {
            enabled: true,
            prevEl: ".".concat(name, "-button-prev"),
            nextEl: ".".concat(name, "-button-next"),
        };
        return _this;
    };
    var addPagination = function () {
        addModule(Pagination);
        options.pagination = __assign({ el: '.swiper-pagination', clickable: true, renderBullet: function (_index, className) { return "<button class=\"".concat(className, "\"></button>"); } }, makeSpreadable(options.pagination));
        return _this;
    };
    addModule(FixTabIndex);
    var getOptions = function () { return options; };
    function makeSpreadable(options) {
        return options && typeof options !== 'boolean' ? options : {};
    }
    return {
        addModule: addModule,
        addAutoplay: addAutoplay,
        addNavigation: addNavigation,
        addPagination: addPagination,
        getOptions: getOptions,
    };
}
